import {DataGrid} from "@cjdev-internal/visual-stack-x/DataGrid";
import {ExpandingInputButton} from "@cjdev-internal/visual-stack-x/ExpandingInputButton";
import {Row} from "@cjdev-internal/visual-stack-x/Row";
import {Button} from "@cjdev-internal/visual-stack-x/Button";
import {Text} from "@cjdev-internal/visual-stack-x/Text";
import {DataGridTitle, DataGridCaption} from "../../constants";
import {DeleteRecord} from "../../Service/Service";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import {Modal, useModal} from "@cjdev-internal/visual-stack-x/Modal";
import {AlertBar} from "../AlertBar/AlertBar";

const Display = (props) => {

    const [mount, openModal, closeModal] = useModal()
    const columnDefs = [
        {field: 'enterpriseId', headerName: 'Enterprise ID', width: 300},
        {field: 'tagId', headerName: 'Tag ID', width: 300},
        {field: 'actionId', headerName: 'Action ID', width: 300},
        {field: 'shop', headerName: 'Shop Name', width: 300},
        {field: 'installation_timestamp', headerName: 'Installation Timestamp', width: 300}
    ]
    const myAlertPropsSuccess = {
        type: "success", closeType: "icon", children: "Record deleted successfully!", onClose: props.closeAlert
    };
    const myAlertPropsFailure = {
        type: "error", closeType: "icon", children: "Could not delete the record!", onClose: props.closeAlert
    };
    const myAlertPropsNotFound = {
        type: "error", closeType: "icon", children: "Record not found in the database or user not allowed to access these records.", onClose: props.closeAlert
    };
    const myAlertPropsUnauthorized = {
        type: "error", closeType: "icon", children: "User not authorized to delete this record!", onClose: props.closeAlert
    };

    const rowActions = () => ([{
            label: "Delete", icon: "minus", onClick: (e) => {
                const enterpriseId = e.rowData.enterpriseId
                const tagId = e.rowData.tagId
                const shop = e.rowData.shop
                const actionId = e.rowData.actionId
                const api_delete = process.env.REACT_APP_LAMBDA_API_RETRIEVE_DELETE
                const params = {
                    shop: shop,
                    tagId: tagId,
                    enterpriseId: enterpriseId,
                    actionId: actionId,
                    firstName: props.User.firstName,
                    lastName: props.User.lastName,
                    email: props.User.emailAddress.toLowerCase()
                };


                const modalProps = {
                    onBackgroundClick: closeModal,
                    onEscapeKeyUp: closeModal,
                    headerTitle: "Delete Record",
                    headerContent: "",
                    onCloseIconClick: closeModal,
                    footer: (<>
                        <Button type="primary" onClick={() => {
                            DeleteRecord(api_delete, params,props.bearerToken).then(response => {
                                switch (response) {
                                    case 204:
                                        props.setAlert(true)
                                        props.openAlert("success",myAlertPropsSuccess)
                                        const newArray = props.rowData.filter((element) => element.enterpriseId !== params.enterpriseId || element.tagId !== params.tagId || element.shop !== params.shop || element.actionId !== params.actionId);
                                        props.setRowData(newArray);
                                        break;
                                    case 404:
                                        props.setAlert(true)
                                        props.openAlert("error",myAlertPropsFailure)
                                        break;
                                    case 500:
                                        props.setAlert(true)
                                        props.openAlert("error",myAlertPropsNotFound)
                                        break;
                                    case 504:
                                        props.setAlert(true)
                                        props.openAlert("error",myAlertPropsUnauthorized)
                                        break;
                                    case 403:
                                        props.setAlert(true)
                                        props.openAlert("error",myAlertPropsUnauthorized)
                                        break;
                                    default:
                                        props.setAlert(true)
                                        props.openAlert("error",myAlertPropsUnauthorized)
                                        break;
                                }
                            })
                            closeModal();
                        }}>
                            DELETE
                        </Button>

                    </>),

                }
                props.setAlert(false)
                openModal(<Modal {...modalProps}>
                    <ConfirmationModal enterpriseId={enterpriseId} tagId={tagId} shop={shop} actionId={actionId}/>
                </Modal>)
            },
        },]

    )
    const topPanel = ({api, columnApi}) => {
        const sizeToFit = () => api.sizeColumnsToFit()
        const autoSize = () => columnApi.autoSizeAllColumns()
        return (<>
            {mount}
            <Row justify="space-between" align="center" gap="medium">
                <Button type="secondary" onClick={sizeToFit}>
                    SIZE TO FIT
                </Button>
                <Button type="secondary" onClick={autoSize}>
                    AUTO-SIZE
                </Button>
                <Row paddingLeft="medium" gap="medium" align="center">
                    <Text style={{whiteSpace: "nowrap"}}>Quick Filter: </Text>
                    <ExpandingInputButton onChange={(v) => api.setQuickFilter(v)}/>
                </Row>
            </Row>
        </>)
    }
    return (

        <div style={{height: "620px"}}>
            <label htmlFor="DataGridComponent"></label>
            {props.showAlert && <AlertBar/>}

            <DataGrid
                rowData={props.rowData}
                columnDefs={columnDefs}
                rowActions={rowActions}
                topPanel={topPanel}
                getRowId={(data) => data.shop}
                pagination
                paginationPageSize={50}
                readOnlyEdit={true}
                onFirstDataRendered={p => p.columnApi.autoSizeAllColumns(false)}
                title={DataGridTitle}
                caption={DataGridCaption}
            />
        </div>);

};
export default Display;
