import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import {handleOAuthCallback} from "@platform/platform-auth/src";

export const AuthCallBack = () => {
    const location = useLocation();
    const nav = useNavigate();

    useEffect(() => {
        handleOAuthCallback();
    }, [location, nav]);

    return <p>Processing authentication...</p>;
};
