import { TopNavLayout } from "@cjdev-internal/visual-stack-x/TopNavLayout";
import "./TopNav.css";
import { TopNavHeading } from "../../constants";
import { useNavigate } from "react-router-dom";
import {logout} from "@platform/platform-auth/src";

const TopNav = () => {

    return (
        <TopNavLayout
            className="top-nav-layout"
            title={TopNavHeading}
            contentProps={{ gap: "large" }}
            actions={{
                submit: {
                    content: "Log out",
                    onClick: () => {
                        logout()
                    },
                },
            }}
        ></TopNavLayout>
    );
};

export default TopNav;
