import './App.css';
import Dependencies from "./components/main/Dependencies";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import React,{useState} from "react";
import {PrivateRoute} from "./components/main/PrivateRoute";
import {AuthCallBack} from "./components/main/AuthCallBack";
import {LogoutPage} from "./components/main/Logout"

function App() {
    const [userData, setUserData] = useState(null)
    const [bearerToken, setBearerToken] = useState(null); // Add bearerToken state
    const [userIdentity, setUserIdentity] = useState(null); // Add bearerToken state

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<PrivateRoute><Dependencies userData={userData} bearerToken={bearerToken} userIdentity={userIdentity}/></PrivateRoute>} />
                <Route path="/Logout" element={<LogoutPage />} />
                <Route path="/oauth-callback" element={<AuthCallBack />} />
            </Routes>
        </BrowserRouter>
    );
}
export default App;
