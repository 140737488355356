export let RetrieveData = async (api_search, updateData, params, bearerToken) => {
    try {
        const headers = {
            'Authorization': `Bearer ${bearerToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        const response = await fetch(api_search, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params)
        });

        const data = await response.json();

        if (response.status === 200) {
            updateData(data, 200);
            return data;
        }else{
            updateData([], response.status);
            return null;
        }
    } catch (error) {
        console.log('Error:', error);
        return null;
    }
};


export const DeleteRecord = async (api_delete, params,bearerToken) => {
    const headers={
        'Authorization':`Bearer ${bearerToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
    try {
        const response = await fetch(api_delete, {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(params)
        });
        return response.status
    } catch (e) {
        return false;
    }
};

export const getUserData=async (apiEndpoint, userId, bearerToken) => {
    const url = `${apiEndpoint}/${userId}?excludeLinkUsers=1&includeActiveOnlyForBackwardsCompatibility=false`;

    try{
        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
            },
        });

        if (!response.ok) {
            return {};
        }

        // Parse the JSON response and return the user data
        return await response.json();
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }

}





