const ConfirmationModal = (props) => {
    return (<>
            <div style={{textAlign: "center"}}>
                <div>Are you sure you want to delete record with <br />
                    <b>  enterpriseId : {props.enterpriseId}</b>,
                    <b>  Shop name : {props.shop}</b>,
                    <b>  ActionId : {props.actionId}</b>,
                    <b>  TagId : {props.tagId}  </b> ?
                </div>
                <br></br>
            </div>
        </>)
}
export default ConfirmationModal;
