import {Form, FormGroup, Input} from "@cjdev-internal/visual-stack-x/Form";
import {Button} from "@cjdev-internal/visual-stack-x/Button";
import '@cjdev-internal/design-tokens'
import './InputForm.css'
import {RetrieveData} from '../../Service/Service'
import {ShopNameInputField, TagIdInputField, ActionIdInputField, EnterpriseIdInputField, SearchButton} from '../../constants'
import React, {useState} from "react";
import {useAlert} from "@cjdev-internal/visual-stack-x/Alert";
import {AlertBar} from "../AlertBar/AlertBar";

const InputForm = (props) => {
    const [shop, setShop] = useState("");
    const [tagId, setTagId] = useState("");
    const [enterpriseId, setEnterpriseId] = useState("");
    const [actionId, setActionId] = useState("");
    const [showAlertI, setShowAlertI] = useState(false)
    const [alertMount, openAlert, closeAlert] = useAlert();
    const handleShopChange = (event) => {
        setShop(event.target.value);
    };
    const handleTagIdChange = (event) => {
        setTagId(event.target.value);
    };
    const handleEnterpriseIdChange = (event) => {
        setEnterpriseId(event.target.value);
    };
    const handleActionIdChange = (event) => {
        setActionId(event.target.value);
    };
    const handleSearchClick = () => {
        const apiUrl = process.env.REACT_APP_LAMBDA_API_RETRIEVE_DELETE
        const params = {shop: shop, tagId: tagId, enterpriseId: enterpriseId, actionId: actionId}

        if (shop.trim() === "" && tagId.trim() === "" && enterpriseId.trim() === "" && actionId.trim() === "") {
            setShowAlertI(true)
            props.updateData([],404)

        } else {
            setShowAlertI(false)

            RetrieveData(apiUrl, props.updateData, params,props.bearerToken)
        }
        setEnterpriseId('');
        setTagId('');
        setShop('');
        setActionId('');
    };
    return (<div>
            {alertMount}
            {showAlertI && <AlertBar/>}

            <div>
                <div className="form-container">
                    <Form>
                        <FormGroup>
                            <div className="form-row">
                                <div className="form-column">
                                    <label htmlFor="shop-name">{ShopNameInputField}</label>
                                    <Input type="text" id="shop-name" value={shop} onChange={handleShopChange} />
                                </div>
                                <div className="form-column">
                                    <label htmlFor="tag-id">{TagIdInputField}</label>
                                    <Input type="text" id="tag-id" value={tagId} onChange={handleTagIdChange} />
                                </div>

                                <div className="form-column">
                                    <label htmlFor="action-id">{ActionIdInputField}</label>
                                    <Input type="text" id="action-id" value={actionId} onChange={handleActionIdChange} />
                                </div>
                                <div className="form-column">
                                    <label htmlFor="enterprise-id">{EnterpriseIdInputField}</label>
                                    <Input type="text" id="enterprise-id" value={enterpriseId} onChange={handleEnterpriseIdChange} />
                                </div>
                            </div>
                            <div className="search-button-div">
                                <Button type="primary" onClick={handleSearchClick}>{SearchButton}</Button>
                            </div>
                        </FormGroup>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default InputForm;

