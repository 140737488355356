import React, {useEffect, useState} from "react";
import {getBearerToken, getUserId} from "@platform/platform-auth/src";
import {getUserData} from "../../Service/Service";
import {LoadPage} from '../LoadPage/LoadPage'

export const PrivateRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const apiEndpoint = 'https://members.cj.com/affapi/oauth/user';
    const [userData, setUserData] = useState(null);
    const [bearerToken,setBearerToken]=useState(null);
    const [userIdentity,setUserIdentity]=useState(null);

    useEffect(() => {
        getBearerToken()
            .then(async (response) => {
                const userId = await getUserId();
                const fetchedUserData = await getUserData(apiEndpoint, userId, response);
                setUserData(fetchedUserData);
                setIsAuthenticated(true);
                setBearerToken(response);
                setUserIdentity(userId);
            })
            .catch((error) => {
                console.error(error);
                setIsAuthenticated(false);
            });
    }, []);

    if (!isAuthenticated) {
        return <LoadPage />;
    }

    return React.cloneElement(children, { bearerToken, userIdentity, userData });
};