import {useState} from "react";
import {DataGridDisplayText, InputFormHeading} from '../../constants'
import {AlertBar} from "../AlertBar/AlertBar";
import {useAlert} from "@cjdev-internal/visual-stack-x/Alert";

const Entry = (props) => {
    const InputForm = props.InputForm;
    const TopNav = props.TopNav;
    const Display = props.Display;
    const AlertBar = props.AlertBar;
    const [data, setData] = useState([])
    const [showAlert, setShowAlert] = useState(false)
    const [alertMount, openAlert, closeAlert] = useAlert();
    const setAlert = (value) => {
        setShowAlert(value);
    };
    const openAlertProps = (alertType, alertProps) => {
        switch (alertType) {
            case "success":
                openAlert({ ...alertProps, type: "success" });
                break;
            case "error":
                openAlert({ ...alertProps, type: "error" });
                break;
            default:
                openAlert(alertProps);
                break;
        }
    };
    function updateData(data, statusCode) {
        closeAlert();


        if (statusCode === 403) {
            const notAuthorizedProps = {
                type: "danger",
                closeType: "icon",
                children: "User not allowed to access these records. Please reach out to Shopify Plugins support team.",
                onClose: closeAlert
            };
            openAlertProps("error", notAuthorizedProps);
        } else if (statusCode === 404) {
            const emptyAlert = {
                type: "danger", closeType: "icon", children: "All fields can't be empty!", onClose: closeAlert
            };
            openAlertProps("error", emptyAlert);
        } else if (data.length > 0) {
            setShowAlert(false);
        } else {
            const doesntExistProps = {
                type: "danger",
                closeType: "icon",
                children: "The record you are searching for does not exist.",
                onClose: closeAlert
            };
            openAlertProps("error", doesntExistProps);
        }

        setData(data);
    }


    return (<div style={{overflow: 'hidden'}}>
        {alertMount}
        <TopNav/>

        <div className="heading" style={{paddingTop: '10em', paddingBottom: '1em'}}>
            <p className="heading-topnav">{InputFormHeading}</p>
        </div>

        <InputForm updateData={updateData} bearerToken={props.bearerToken} userIdentity={props.userIdentity}/>
        <br/>
        {data.length > 0 ? (<Display rowData={data} setRowData={setData} User={props.userData} setAlert={setAlert} showAlert={showAlert}
                                     openAlert={openAlertProps} closeAlert={closeAlert} bearerToken={props.bearerToken} userIdentity={props.userIdentity} /> ) : (
            <p className="noData" style={{textAlign: 'center'}}>{DataGridDisplayText}</p>)}
        {showAlert && <AlertBar/>}
    </div>)

};
export default Entry;
