export const TopNavHeading="Shopify DB Reset";
export const InputFormHeading="Enter any of the details below to retrieve the store details from CJ Shopify store database";
export const ShopNameInputField="Shop Name";
export const TagIdInputField="Tag ID";
export const ActionIdInputField="Action ID";
export const EnterpriseIdInputField="Enterprise ID";
export const SearchButton="SEARCH";
export const DataGridDisplayText="No data available. Try entering valid input in the above fields.";
export const DataGridTitle="Shopify Database";
export const DataGridCaption="Select the record you want to Delete.";
